.heart {
  position: relative;
  width: 100px;
  height: 90px;
  margin-top: 10px;
  z-index: 10; /* Höherer Wert bringt das Element in den Vordergrund */
}

.heart::before, .heart::after {
  content: "";
  position: absolute;
  top: 0;
  width: 52px;
  height: 80px;
  border-radius: 50px 50px 0 0;
  background: #8e7b50;
  z-index: 10; /* Stellt sicher, dass die Pseudo-Elemente auch im Vordergrund bleiben */
}

.heart::before {
  left: 50px;
  transform: rotate(-45deg);
  transform-origin: 0 100%;
}

.heart::after {
  left: 0;
  transform: rotate(45deg);
  transform-origin: 100% 100%;
}
